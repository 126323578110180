<template>
  <div id="problem-classification-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-folder"></i>
          <span style="margin-left: 5px"> Klasifikasi Masalah </span>
        </b-card-title>
        <!-- add problem classification button -->
        <b-button
          variant="outline-info"
          class="ml-auto mr-1"
          v-b-modal.modal-add-problem-classification
        >
          <feather-icon icon="PlusIcon" />
          <span>Tambah Klasifikasi</span>
        </b-button>
        <!-- keyword filter -->
        <b-input-group class="input-group-merge" style="width: 25vw">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input placeholder="Masukkan kata kunci..." v-model="filter" />
        </b-input-group>
      </b-card-header>
      <!-- loader component -->
      <loader-component v-if="is_loading" />
      <!-- table component -->
      <table-component
        v-else
        :data="table_data"
        :editItems="editProblemClassification"
        :deleteItems="deleteProblemClassification"
        class="mt-1"
      />
    </b-card>
    <!-- add user  modal component -->
    <add-problem-classification-component />
    <!-- edit user modal component -->
    <edit-problem-classification-component
      :data="problem_classification_data"
    />
  </div>
</template>
<script>
import $ from "jquery";
import useJwt from "@/auth/jwt/useJwt";
import dataTable from "@/views/components/dataTable.vue";
import loader from "@/views/components/loader.vue";
import addProblemClassification from "@/views/components/problem-classification/addProblemClassification.vue";
import editProblemClassification from "@/views/components/problem-classification/editProblemClassification.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  VBModal,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    "table-component": dataTable,
    "loader-component": loader,
    "add-problem-classification-component": addProblemClassification,
    "edit-problem-classification-component": editProblemClassification,
  },
  data() {
    return {
      is_loading: true,
      filter: "",
      table_data: {
        page_info: {
          limit: 10,
          page: 1,
          total: 0,
        },
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "name",
            label: "jenis klasifikasi",
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "description",
            label: "deskripsi",
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "actions",
            label: "aksi",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "5%", paddingRight: "2rem" },
          },
        ],
        items: [],
      },
      problem_classification_data: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "$route.query"() {
      this.table_data.page_info.page = this.$route.query.page
        ? this.$route.query.page
        : 1;
      this.table_data.page_info.limit = this.$route.query.items
        ? this.$route.query.items
        : 10;
      this.getData();
    },
    filter() {
      if (!this.filter || this.filter.length > 2 || this.filter.length == 0) {
        this.updateQuery("page", 1);
        this.getData();
      }
    },
  },
  methods: {
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if ($.param(this.$route.query) != $.param(queries)) {
        this.$router.replace({ query: queries });
      }
    },
    getData() {
      this.is_loading = true;
      let params = {
        page: this.table_data.page_info.page,
        item: this.table_data.page_info.limit,
        ...(this.filter ? { key: this.filter } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "problem-classification?" + query;
      useJwt.axiosIns
        .get(api)
        .then((res) => {
          this.table_data.items = res.data.problem_classification_data || [];
          this.table_data.page_info.total = res.data.pagination_info.count || 0;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (this.table_data.items) {
            this.is_loading = false;
          }
        });
    },
    editProblemClassification(id) {
      let filter_val = this.table_data.items.filter((el) => el._id == id);
      let data = filter_val[0];
      this.problem_classification_data = {
        _id: data._id,
        name: data.name,
        description: data.description,
      };
      this.$bvModal.show("modal-edit-problem-classification");
    },
    deleteProblemClassification(id, nama) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: `<strong>${nama}</strong> Akan dihapus dari daftar klasifikasi masalah`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let api =
            process.env.VUE_APP_API_URL + "problem-classification/delete/" + id;
          useJwt.axiosIns
            .delete(api)
            .then(() => {
              this.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Data Klasifikasi Masalah Telah Dihapus!`,
                },
              });
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Gagal !",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: `Data Klasifikasi Masalah Gagal Dihapus!`,
                },
              });
            });
        }
      });
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
